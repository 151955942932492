import React from 'react';
import styled from 'styled-components';
import {useWebformElement, WebformElementWrapper} from 'gatsby-drupal-webform';
import { TextInputSmall } from "../styles/Global";

const WebformEntityInput = ({ element }) => {

  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: 'textfield'
  });

  const placeAttribute = element.attributes.find(attr => attr.name === '#placeholder');
  const labelAttribute = element.attributes.find(attr => attr.name === '#attributes');

  const placeholder = placeAttribute ? placeAttribute.value : null;

  return (
    <Wrapper settings={settings}>
      <Content>
        <TextInputSmall
          {...inputProps}
          placeholder={element.name}
          as={element.type === 'textarea' ? 'textarea' : null}
          rows={ 3 }
        />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled(WebformElementWrapper)`
  margin: 0 0 0 0;
  position: relative;
  padding: 0 0;
`;

const Content = styled.div`
  overflow: hidden;
  position: relative;
`;

const InputElement = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  color: #767676;
  line-height: 30px;
  font-family: Raleway, sans-serif;
  padding: 0.85em 1.7em 0.85em 2.1em;
  border-bottom: 1px solid rgba(22, 24, 30, 0.25);
  transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1) 0.1s;
  ${Wrapper}:hover & {
    padding-left: 0;
  }
`;

export default WebformEntityInput;
