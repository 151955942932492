import React from 'react';
import styled from 'styled-components';

const WebformEntitySubmit = ({ element }) => {
  const submitLabel = element.attributes.find(attr => attr.name === "#submit__label")

  return (
    <Wrapper>
      <ButtonBlack id={'aboutform'} type={'submit'}>
        {submitLabel.value}
      </ButtonBlack>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonBlack = styled.button`
  height: 35px;
  width: 100%;
  border: 4px solid #516C93;
  background: transparent;
  border-radius: 18px;
  color: #31435A;
  font-family: ‘Roboto’, sans-serif;
  font-size: 18px;
  ::after {
    background-color: #ffffff;
    transform: scaleX(0);
    transform-origin: left;
  }
  :hover, :focus {
    color: black;
    ::after {
      transform: scaleX(1);
      transform-origin: right;
    }
  }
`;

export default WebformEntitySubmit;
