import React, { useState } from "react";
import Layout from "../layouts";
import { ContactSection, InfoSection, InfoHeader, InfoTextHeader, InfoBody, ContactInfo, ContactLogo, InfoTextBox, InfoText, MessageBox, MessageFormName, MessageFormBody, TextInputSmall, TextInputLarge, ButtonSubmit } from '../styles/Global'
import Webform from "gatsby-drupal-webform";
import WebformEntityInput from "../components/WebformEntityInput";
import WebformEntitySubmit from "../components/WebformEntitySubmit";


export default function Home({ data: { webformWebform: webform } }) {
    const [submitted, setSubmitted] = useState(false);
    return (
        <Layout>
            <ContactSection>
                <InfoSection>
                    <InfoHeader contact>
                        <InfoTextHeader>
                            Contact Us
                        </InfoTextHeader>
                    </InfoHeader>
                    <InfoBody contact>
                        <ContactInfo>
                            <ContactLogo>
                            </ContactLogo>
                            <InfoTextBox>
                                <InfoText>
                                    509.226.1200
                                </InfoText>
                                <InfoText light>
                                    Call us
                                </InfoText>
                            </InfoTextBox>
                        </ContactInfo>
                        <MessageBox>
                            <Webform
                              webform={webform}
                              endpoint={'https://content.jlte.ch/react_webform_backend/submit'}
                              customComponents={{
                                  webform_name: WebformEntityInput,
                                  textfield: WebformEntityInput,
                                  email: WebformEntityInput,
                                  tel: WebformEntityInput,
                                  textarea: WebformEntityInput,
                                  webform_actions: WebformEntitySubmit
                              }}
                              onSuccess={() => setSubmitted(true)}
                            />
                        </MessageBox>
                        <ContactInfo>
                            <ContactLogo email>
                            </ContactLogo>
                                <InfoTextBox>
                                    <InfoText>
                                        spokanesweeps@gmail.com
                                    </InfoText>
                                    <InfoText light>
                                        Email us
                                    </InfoText>
                                </InfoTextBox>
                        </ContactInfo>
                    </InfoBody>
                </InfoSection>
            </ContactSection>
        </Layout>
    );
  }

export const query = graphql`
  query {
    webformWebform(drupal_internal__id: { eq: "l5jtssunev0a7zquhssm7cbizndgaqxl" }) {
      drupal_internal__id
      elements {
          name
          type
          attributes {
            name
            value
          }
      }
    }
  }
`
